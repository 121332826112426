import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithFacebook } from '../Components/UserAuth.api';
import './SignUp.css';

const SignUp = () => {
  const navigate = useNavigate();

  const handleGoogleSignUp = async () => {
    try {
      await signInWithGoogle();
      navigate(-1); // Go back to previous page after successful sign-up
    } catch (error) {
      console.error('Google sign-up error:', error);
    }
  };

  const handleFacebookSignUp = async () => {
    try {
      await signInWithFacebook();
      navigate(-1); // Go back to previous page after successful sign-up
    } catch (error) {
      console.error('Facebook sign-up error:', error);
    }
  };

  return (
    <div className="signup-container">
      <h1>Create your Clipvest account</h1>
      <p>Join to start investing in your favorite content creators</p>
      
      <div className="auth-options">
        <button className="google-auth" onClick={handleGoogleSignUp}>
          <img src="/google-icon.png" alt="Google" />
          Continue with Google
        </button>
        
        <button className="facebook-auth" onClick={handleFacebookSignUp}>
          <img src="/facebook-icon.png" alt="Facebook" />
          Continue with Facebook
        </button>

        <div className="divider">
          <span>or</span>
        </div>

        {/* <button className="email-auth" onClick={() => navigate('/signup/email')}> */}
          Wait for our larger release. We'll open up to the public soon.
        {/* </button> */}
      </div>

      <p className="signin-prompt">
        {/* Already have an account?{' '} */}
        {/* <button onClick={() => navigate('/signin')}>Sign in</button> */}
      </p>
    </div>
  );
};

export default SignUp; 