import { API_URL } from "../../globals";

export const getVideoSearchResults = async (searchTerm, options = {}) => {
  try {
    const params = new URLSearchParams({ 
      q: searchTerm,
      ...options 
    });
    
    const url = `${API_URL}/videos/search?${params}`;
    console.log('Fetching from URL:', url);
    
    const response = await fetch(url);
    
    if (!response.ok) {
      const data = await response.json();
      console.log('Response not OK:', response.status, data);
      throw new Error(data.error || 'Search failed');
    }
    
    const data = await response.json();
    console.log('API response data:', data);
    return data.results;
  } catch (err) {
    console.error("Error searching for videos:", err);
    throw err;
  }
};
