const API_URL = process.env.REACT_APP_API_URL;

export const fetchVideos = async (userId, page, limit = 9, offset = 0) => {
  try {
    const response = await fetch(
      `${API_URL}/listAllLibraryVideos?user_id=${userId}&limit=${limit}&offset=${offset}`
    );
    if (!response.ok) {
      throw new Error(`Fetch error: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw error;
  }
};

export const updateVideoTitle = async (title, videoId) => {
  try {
    const response = await fetch(`${API_URL}/videos/${videoId}/update_title`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.video; // Return the updated video object
  } catch (error) {
    console.error('Error updating video title:', error);
    throw error;
  }
};
