import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TradeModal from './TradeModal';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer here
import './TradeButton.css';  // Add this line after other imports

const API_URL = process.env.REACT_APP_API_URL;
const notifyUserSignInError = () => toast.error('Please create an account or sign in to trade.');

const TradeButton = ({id, orderType}) => {
    const currentUser = useUser();
    const [videoData, setVideoData] = useState(null);
    const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);
    const [tradeType, setTradeType] = useState(orderType || 'buy');
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

      
    const handleTradeModalClose = () => {
        setIsTradeModalOpen(false);
    };

    const handleTradeModalOpen = (type) => {
        setTradeType(type);
        setIsTradeModalOpen(true);
    };

    async function fetchVideoData(id) {
        try {
          const response = await fetch(`${API_URL}/videos/${id}`);
          const data = await response.json();
          setVideoData(data);
          const userResponse = await fetch(`${API_URL}/user/${data.user_id}`);
          const userData = await userResponse.json();
          setUserData(userData);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching video data:', error);
          setLoading(false);
        }
      }
  
      useEffect(() => {
        fetchVideoData(id);
    }, [id]);

    return (
        <>
            <div className='investment-cta'>
            {orderType === 'buy' && (
                    <button onClick={() => handleTradeModalOpen('buy')}>BUY</button>
                )}
                {orderType === 'sell' && (
                    <button onClick={() => handleTradeModalOpen('sell')}>SELL</button>
                )}
            </div>
            {isTradeModalOpen && (
                <Dialog open={isTradeModalOpen} onClose={handleTradeModalClose}>
                    <DialogTitle>Trade</DialogTitle>
                    <DialogContent>
                        <TradeModal id={id} tradeType={tradeType} onClose={handleTradeModalClose} />
                    </DialogContent>
                </Dialog>
            )}

        </>
    );
};

export default TradeButton;