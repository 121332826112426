import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from '@mui/material/CircularProgress';
import { getVideoSearchResults } from './Search.api';
import "./Searchbar.css";

export default function Searchbar({ onSearchChange }) {
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(
    debounce(async (searchTerm) => {
      console.log('Search term:', searchTerm);

      if (!searchTerm.trim()) {
        onSearchChange([]);
        return;
      }

      setLoading(true);
      try {
        const results = await getVideoSearchResults(searchTerm);
        console.log('Search results:', results);
        onSearchChange(results || []);
      } catch (error) {
        console.error('Error fetching search results:', error);
        onSearchChange([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    [onSearchChange]
  );

  return (
    <div className="search">
      <TextField
        id="outlined-basic"
        variant="outlined"
        fullWidth
        label="Search"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div className="search-icon-container">
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SearchIcon className="search-icon" />
        )}
      </div>
    </div>
  );
} 