import React, { useState, useEffect } from 'react';
import { useUser } from '../Components/UserContext';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../globals';
import './ChannelSettings.css';

const ChannelSettings = () => {
  const currentUser = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [channelData, setChannelData] = useState({
    display_name: '',
    handle: '',
    photo_url: ''
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeUser = setTimeout(() => {
      if (!currentUser) {
        navigate('/');
        return;
      }

      const fetchChannelData = async () => {
        try {
          setChannelData({
            display_name: currentUser.displayName || '',
            handle: '',
            photo_url: currentUser.photoURL || ''
          });

          const response = await fetch(`${API_URL}/user/${currentUser.uid}`);
          const data = await response.json();
          
          if (response.ok) {
            setChannelData({
              display_name: data.display_name || currentUser.displayName || '',
              handle: data.handle || '',
              photo_url: data.photo_url || currentUser.photoURL || ''
            });
          }
          setLoading(false);
        } catch (err) {
          setError('Failed to load channel data');
          setLoading(false);
        }
      };

      fetchChannelData();
    }, 1000);

    return () => clearTimeout(initializeUser);
  }, [currentUser, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('File size must be less than 5MB');
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      let photoUrl = channelData.photo_url;
      if (previewImage) {
        console.log('Preparing to upload image');
        
        const formData = new FormData();
        const fileInput = document.getElementById('profile-image');
        const file = fileInput.files[0];
        
        if (!file) {
          throw new Error('No file selected');
        }
        
        formData.append('image', file);
        
        console.log('Sending upload request');
        const uploadResponse = await fetch(`${API_URL}/upload/profile-image/${currentUser.uid}`, {
          method: 'POST',
          body: formData,
          credentials: 'include'
        });
        
        if (!uploadResponse.ok) {
          const errorText = await uploadResponse.text();
          console.error('Upload failed:', errorText);
          throw new Error(errorText || 'Failed to upload profile image');
        }
        
        const uploadData = await uploadResponse.json();
        photoUrl = uploadData.url;
        console.log('Upload successful:', uploadData);
      }

      const response = await fetch(`${API_URL}/user/${currentUser.uid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          display_name: channelData.display_name,
          handle: channelData.handle,
          photo_url: photoUrl
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update channel');
      }

      const data = await response.json();
      console.log('Update successful:', data);

      navigate('/Library');
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="channel-settings-container">
      <h1>Channel Settings</h1>
      
      {error && <div className="error-message">{error}</div>}
      
      <form onSubmit={handleSubmit} className="channel-settings-form">
        <div className="profile-image-section">
          <img 
            src={previewImage || channelData.photo_url || '/default-avatar.png'} 
            alt="Profile" 
            className="profile-preview"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="profile-image"
            className="hidden"
          />
          <label htmlFor="profile-image" className="upload-button">
            Change Profile Picture
          </label>
        </div>

        <div className="form-group">
          <label>Display Name</label>
          <input
            type="text"
            value={channelData.display_name}
            onChange={(e) => setChannelData({...channelData, display_name: e.target.value})}
            placeholder="Enter display name"
            maxLength={50}
          />
        </div>

        <div className="form-group">
          <label>Handle</label>
          <div className="handle-input">
            <span>@</span>
            <input
              type="text"
              value={channelData.handle}
              onChange={(e) => setChannelData({...channelData, handle: e.target.value.toLowerCase()})}
              placeholder="your-handle"
              pattern="^[a-z0-9_-]{3,20}$"
              title="Handle must be 3-20 characters long and can only contain letters, numbers, underscores, and hyphens"
            />
          </div>
        </div>

        <button type="submit" className="save-button">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default ChannelSettings; 