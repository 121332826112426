import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDMZnt827rDLiNvDKjfrzmSff3VbLvlt_k",
    authDomain: "divvy-e9390.firebaseapp.com",
    projectId: "divvy-e9390",
    storageBucket: "divvy-e9390.appspot.com",
    messagingSenderId: "502879804886",
    appId: "1:502879804886:web:9c33a5ff11c9b4cc015877",
    measurementId: "G-5BWEZK200J"
  };

  // Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
export const db = getFirestore(app);