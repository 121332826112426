import React, { useState, useEffect, useCallback } from "react";
import { useUser } from "./UserContext";
import "./TradeModal.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify"; // Import ToastContainer here
import { useNavigate } from 'react-router-dom';

import { API_URL, formatNumberWithCommas } from "../globals";
import { signInWithGoogle } from "./UserAuth.api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const notifySuccess = () => toast.success("Trade successfully completed!");

const TradeModal = ({ id, tradeType, onClose }) => {
  const currentUser = useUser();
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [shares, setShares] = useState(0); // User specified number of shares
  const [sharesOwned, setSharesOwned] = useState(0); // State for tracking the number of shares owned
  const [orderType, setOrderType] = useState("buy"); //buy or sell order
  const [orderMode, setOrderMode] = useState("market"); //market or limit order
  const [totalPrice, setTotalPrice] = useState(0); // Calculated total price
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const [showSignInButton, setShowSignInButton] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [priceData, setPriceData] = useState({
    bid: 0,
    ask: 0,
    spread: 0,
    basePrice: 0
  });

  const notifySignIn = () => {
    toast.error("Please sign in to execute the trade.", {
      onClick: () => {
        console.log("Toast clicked"); // Debug log
        setIsSignInDialogOpen(true);
      },
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { cursor: "pointer" }
    });
  };

  const fetchVideoData = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/videos/${id}`);
      const data = await response.json();
      const userResponse = await fetch(`${API_URL}/user/${data.user_id}`);
      const userData = await userResponse.json();

      setVideoData(data);
      setUserData(userData);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  }, [id]);

  const fetchResourceOwnership = useCallback(async () => {
    if (!currentUser) {
      console.error("No user information available");
      return;
    }

    if (!videoData) {
      console.error("No video data available at the moment");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/getResourceOwnership/${currentUser.uid}/${videoData.id}`
      );
      if (response.ok) {
        const data = await response.json();

        setSharesOwned(data || 0);
      } else {
        console.error("Error fetching resource ownership data");
      }
    } catch (error) {
      console.error("Error fetching resource ownership data:", error);
    }
  }, [currentUser, videoData]);

  useEffect(() => {
    if (tradeType === "buy" || tradeType === "sell") {
      setOrderType(tradeType);
    }
  }, [tradeType]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchVideoData();
    };

    fetchData();
  }, [fetchVideoData]);

  useEffect(() => {
    const fetchData = async () => {
      if (videoData?.id) {
        await fetchResourceOwnership();
      }
    };

    fetchData();
  }, [fetchResourceOwnership, videoData]);

  // Function to calculate total price
  // const calculateTotalPrice = () => {
  //   const price = videoData?.share_price || 0;
  //   setTotalPrice(shares * price);
  // };

  const executeTrade = async () => {
    if (!currentUser) {
      setShowSignInButton(true);
      return;
    }

    
      const creatorResponse = await fetch(`${API_URL}/user/${videoData.user_id}`);
      if (!creatorResponse.ok) {
        throw new Error('Failed to fetch creator details');
      }
      const creatorData = await creatorResponse.json();

    try {
      // Fetch latest price data first
      const priceResponse = await fetch(`${API_URL}/price/${videoData.id}`);
      const currentPriceData = await priceResponse.json();
      
      const tradeData = {
        userId: currentUser.uid,
        resourceId: videoData?.id,
        creatorId: videoData.user_id,
        creatorStripeAccountId: creatorData.stripe_account_id,
        shares: shares,
        orderType: orderType,
        sharePrice: orderType === 'buy' ? currentPriceData.ask : currentPriceData.bid,
        orderMode: orderMode,
        orderStatus: "pending",
      };

      const response = await fetch(`${API_URL}/trade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tradeData),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("Trade executed successfully");
        notifySuccess(); // Trigger the success notification
        // Handle successful response
      } else if (response.status === 402) {
        const errorData = await response.json();
        console.error("Payment failed:", errorData.error);
        toast.error(errorData.error); // Display the error message from the server
        // Handle payment failure
      } else {
        toast.error(responseData.error || "An error occurred while executing the trade.");
      }
    } catch (error) {
      console.error("Error making the API call", error);
      toast.error("An error occurred while making the API call."); // Display a generic error message
      // Handle network error
    }
  
    const totalPrice = shares * videoData?.share_price;
  };

  const handleSignInDialogClose = (callback) => {
    setIsSignInDialogOpen(false);
    if (callback) {
      callback();
    }
  };

  const handleSignUp = () => {
    onClose(); // Close the trade modal
    navigate('/signup'); // Navigate to signup page
  };

  const handleSignIn = () => {
    onClose(); // Close the trade modal
    navigate('/signin'); // Navigate to signin page
  };

  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await fetch(`${API_URL}/price/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPriceData(data);
        
        // Calculate total price using current price data
        const currentTradePrice = orderType === 'buy' ? data.ask : data.bid;
        setTotalPrice(shares * currentTradePrice);
      } catch (error) {
        console.error('Error fetching current price:', error);
        // Fallback to video's initial price if price fetch fails
        if (videoData?.share_price) {
          setPriceData({
            bid: videoData.share_price,
            ask: videoData.share_price,
            spread: 0,
            basePrice: videoData.share_price
          });
          setTotalPrice(shares * videoData.share_price);
        }
      }
    };

    if (id) {  // Only fetch if we have a video ID
      fetchCurrentPrice();
      const interval = setInterval(fetchCurrentPrice, 5000);
      return () => clearInterval(interval);
    }
  }, [id, orderType, shares, videoData]);

  // Add this useEffect to handle total price updates
  useEffect(() => {
    const currentTradePrice = orderType === 'buy' 
      ? Number(priceData?.ask || 0)
      : Number(priceData?.bid || 0);
    setTotalPrice(shares * currentTradePrice);
  }, [shares, orderType, priceData]); // Dependencies that should trigger a recalculation

  return (
    <>
      <div className="trade-modal-container">
        <div className="trade-modal">
          <div className="modal-header">
            <div className="video-info">
              <img
                src={videoData?.thumbnail_filepath}
                alt="Thumbnail"
                className="thumbnail"
              />
              <div className="video-details">
                <h2>{videoData?.title}</h2>
                <p className="trade-type">{tradeType === "buy" ? "Buy Shares" : "Sell Shares"}</p>
              </div>
            </div>
            <button className="close-button" onClick={onClose}>×</button>
          </div>

          <div className="trade-content">
            <div className="order-details-section">
              <h3>Order Details</h3>
              <div className="details-grid">
                <div className="detail-item">
                  <span className="label">Date:</span>
                  <span className="value">{new Date().toLocaleDateString()}</span>
                </div>
                <div className="detail-item">
                  <span className="label">Initial Price:</span>
                  <span className="value">${videoData?.share_price}</span>
                </div>
                <div className="detail-item">
                  <span className="label">Current {orderType === "buy" ? "Ask" : "Bid"} Price:</span>
                  <span className="value">
                    ${orderType === "buy" 
                      ? (Number(priceData?.ask || 0)).toFixed(2)
                      : (Number(priceData?.bid || 0)).toFixed(2)}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="label">Shares Outstanding:</span>
                  <span className="value">
                    {formatNumberWithCommas(videoData?.shares_created - videoData?.shares_purchased)}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="label">Your Shares:</span>
                  <span className="value">{sharesOwned}</span>
                </div>
              </div>
            </div>

            <div className="trade-execution-section">
              <div className="shares-input">
                <label>Number of Shares to {tradeType === "buy" ? "Buy" : "Sell"}:</label>
                <input
                  type="number"
                  value={shares}
                  onChange={(e) => {
                    const newShares = parseInt(e.target.value);
                    setShares(newShares);
                  }}
                  placeholder="Enter quantity"
                />
              </div>

              <div className="order-type">
                <label>Order Type:</label>
                <select
                  value={orderMode}
                  onChange={(e) => setOrderMode(e.target.value)}
                >
                  <option value="market">Market Order</option>
                </select>
              </div>

              <div className="total-section">
                <span className="total-label">Estimated Total:</span>
                <span className="total-amount">
                  ${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : '0.00'}
                </span>
              </div>

              <button 
                className={`execute-button ${!currentUser && showSignInButton ? 'disabled' : ''}`} 
                onClick={executeTrade}
              >
                Execute {tradeType === "buy" ? "Buy" : "Sell"} Order
              </button>

              {!currentUser && showSignInButton && (
                <div className="auth-buttons">
                  <button 
                    className="signup-button"
                    onClick={handleSignUp}
                  >
                    Sign Up
                  </button>
                  <button 
                    className="signin-button"
                    onClick={handleSignIn}
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>

            <div className="disclosure">
              <p className="order-note">
                *Market orders execute immediately at the current share price
              </p>
              <p className="legal">
                By placing your order, you agree to Divvy's privacy notice and conditions of use.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <Dialog 
        open={isSignInDialogOpen} 
        onClose={() => handleSignInDialogClose()}
        sx={{
          '& .MuiDialog-paper': {
            zIndex: 9999
          }
        }}
      >
        <DialogTitle>Sign In to Trade</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please sign in to continue with your trade
          </DialogContentText>
          <Button
            onClick={() => {
              handleSignInDialogClose();
              onClose(); // Close the trade modal
              signInWithGoogle(); // Trigger Google sign-in
            }}
            color="primary"
            fullWidth
            variant="contained"
            style={{ marginTop: '20px' }}
          >
            Sign in with Google
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSignInDialogClose()} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TradeModal;
