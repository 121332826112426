import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './AddPaymentMethod.css';
import { auth } from "../firebase";

const AddPaymentMethod = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      console.error('Stripe or elements not initialized');
      return;
    }

    try {
      // Create PaymentMethod
      console.log('Creating payment method...');
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.error('Stripe createPaymentMethod error:', error);
        setError(error.message);
        setLoading(false);
        return;
      }

      console.log('Payment method created:', paymentMethod.id);

      // Send PaymentMethod to your backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment-methods`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          userId: auth.currentUser.uid,
        }),
      });

      const responseData = await response.json();
      
      if (!response.ok) {
        console.error('Backend error:', responseData);
        throw new Error(responseData.details || 'Failed to save payment method');
      }

      console.log('Payment method saved successfully:', responseData);
      onClose();
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h3>Add Payment Method</h3>
      <div className="card-element-container">
        <CardElement 
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
            hidePostalCode: true
          }}
        />
      </div>
      {error && <div className="error-message">{error}</div>}
      <button 
        type="submit" 
        disabled={!stripe || loading}
        className="submit-button"
      >
        {loading ? 'Processing...' : 'Add Payment Method'}
      </button>
    </form>
  );
};

export default AddPaymentMethod; 