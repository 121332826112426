import React, { useEffect, useState } from 'react';
import './WalletList.css';
import Video from './Video';
import { useUser } from './UserContext';
import TradeButton from './TradeButton';
import Searchbar from './Search/Header';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentMethod from './AddPaymentMethod';
import Dialog from "@mui/material/Dialog";
import { useNavigate, useSearchParams } from 'react-router-dom';
import './shared/GridLayout.css';
const API_URL = process.env.REACT_APP_API_URL;

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);

// Add this constant at the top of your file
const RESOURCES_PER_PAGE = 5; // Set constant for resources per page

const Walletlist = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(searchParams.get('page')) || 1;
    });
    const currentUser = useUser();
    const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const [tradeModalAnchor, setTradeModalAnchor] = useState(null); // State to manage popover anchor
    const [tradeType, setTradeType] = useState(null); // Track trade type: 'buy' or 'sell'
    const navigate = useNavigate();

    // Add new state for total stats
    const [totalStats, setTotalStats] = useState({
        totalOwnedResources: 0,
        totalPortfolioValue: 0
    });

    // Modify fetchResources to include a separate call for totals
    const fetchResources = async (page) => {
        if (!currentUser?.uid) return;
        
        setLoading(true);
        try {
            const limit = RESOURCES_PER_PAGE;
            const offset = (page - 1) * limit;
            
            const [resourcesResponse, totalsResponse] = await Promise.all([
                // Fetch paginated resources from resources endpoint
                fetch(`${API_URL}/listAllOwnedResources?user_id=${currentUser.uid}&limit=${limit}&offset=${offset}`),
                // Fetch total stats from videos endpoint
                fetch(`${API_URL}/listAllOwnedResourcesTotals?user_id=${currentUser.uid}`)
            ]);

            if (!resourcesResponse.ok || !totalsResponse.ok) {
                throw new Error(`Fetch error`);
            }

            const [resourcesData, totalsData] = await Promise.all([
                resourcesResponse.json(),
                totalsResponse.json()
            ]);

            // Update totals
            setTotalStats({
                totalOwnedResources: totalsData.totalCount,
                totalPortfolioValue: totalsData.totalValue
            });

            // Update paginated resources
            if (Array.isArray(resourcesData)) {
                const resourcesWithDetails = await Promise.all(
                    resourcesData.map(async (obj) => {
                        const resource_id = obj['resource_id'];
                        const resource_info = await fetchVideoData(resource_id);
                        return { ...obj, ...resource_info };
                    })
                );
                setResources(resourcesWithDetails);
            } else {
                setResources([]);
            }
        } catch (error) {
            console.error('Error fetching resources:', error);
            setResources([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchVideoData = (async (resource_id) => {
        try {
          const response = await fetch(`${API_URL}/videos/${resource_id}`);
          const data = await response.json();
          return data;
        //   console.log(data)
        } catch (error) {
          console.error("Error fetching video data:", error);
        }
      });

    useEffect(() => {
        if (currentUser?.uid) {
            fetchResources(currentPage);
        }
    }, [currentPage, currentUser?.uid]);

    const handlePaymentModalClose = () => {
        setIsPaymentModalOpen(false);
    };

    const handleSearch = (event) => {
        // Implement search functionality if needed
        console.log('Search term:', event.target.value);
    };

    // Update URL when page changes
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setSearchParams({ page: newPage });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!currentUser) {
        return <div>Please log in to view your wallet</div>;
    }

    return (
        <div>
            <Searchbar onSearchChange={handleSearch} />
            <div className="wallet-container">
                <div className="wallet-header">
                    <div className="portfolio-summary">
                        <div className="metric">
                            <h2 className="metric-value">{totalStats.totalOwnedResources}</h2>
                            <p className="metric-label">Total Owned Resources</p>
                        </div>
                        <div className="metric">
                            <h2 className="metric-value">${totalStats.totalPortfolioValue.toFixed(2)}</h2>
                            <p className="metric-label">Total Portfolio Value</p>
                        </div>
                    </div>
                    <div className="header-actions">
                        <button 
                            className="action-button"
                            onClick={() => setIsPaymentModalOpen(true)}
                        >
                            Add Payment Method
                        </button>
                        <button 
                            className="action-button"
                            onClick={() => navigate('/order-history')}
                        >
                            Order History
                        </button>
                    </div>
                </div>

                {resources.length === 0 ? (
                    <>
                        <div className="no-resources-message">
                            No more resources found in your wallet
                        </div>
                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className="page-indicator">Page {currentPage}</span>
                            <button 
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={true}
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="resources-grid">
                            {resources.map(resource => (
                                <div key={resource.resource_id} className="resource-card">
                                    <Video id={resource.resource_id} />
                                    <div className="resource-details">
                                        <div className="resource-stats">
                                            <div className="stat-group">
                                                <span>Market Cap: ${(resource.shares_created * resource.share_price).toFixed(2)}</span>
                                                <span>Price/Share: ${resource.share_price}</span>
                                            </div>
                                            <div className="stat-group">
                                                <span>Shares Owned: {resource.shares_owned}</span>
                                                <span>Total Value: ${(resource.shares_owned * resource.share_price).toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="trade-actions">
                                            <TradeButton id={resource.resource_id} orderType={'buy'} />
                                            <TradeButton id={resource.resource_id} orderType={'sell'} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className="page-indicator">Page {currentPage}</span>
                            <button 
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={resources.length < RESOURCES_PER_PAGE}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}

                <Dialog 
                    open={isPaymentModalOpen} 
                    onClose={handlePaymentModalClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <div className="stripe-security-message">
                        <img 
                            src="https://stripe.com/img/v3/home/social.png" 
                            alt="Stripe" 
                            style={{ height: '75px', marginRight: '8px' }}
                        />
                        <p>
                            Payment methods are securely collected and managed by Stripe. 
                            Your financial information never touches our servers.
                            <a 
                                href="https://stripe.com/docs/security" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: '#635BFF', marginLeft: '4px' }}
                            >
                                Learn more
                            </a>
                        </p>
                    </div>
                    <Elements stripe={stripePromise}>
                        <AddPaymentMethod onClose={handlePaymentModalClose} />
                    </Elements>
                </Dialog>
            </div>
        </div>
    );
};

export default Walletlist;