import React from "react";
import "./SideMenu.css";
import HomeIcon from "@mui/icons-material/Home";
import InvestIcon from "@mui/icons-material/AccountBalance";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LibraryIcon from "@mui/icons-material/VideoLibrary";
import MenuIcon from "@mui/icons-material/Menu";
import HistoryIcon from "@mui/icons-material/History";

import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

export default function SideMenu() {
  const [user, loading, error] = useAuthState(auth);

  return (
    <div className="side-menu">
      <MenuIcon className="side-menu-icon hamburger-menu" />
      <Link to="/">
        <div className="icon-container">
          <HomeIcon className="side-menu-icon" />
          <span className="icon-text">Home</span>
        </div>
      </Link>
      {user && <Link to="/view_history">
      <div className="icon-container">
        <HistoryIcon className="side-menu-icon" />
        <span className="icon-text">Recent History</span>
      </div>
      </Link>
      }
      {user && <Link to="/Wallet">
      <div className="icon-container">
        <WalletIcon className="side-menu-icon" />
        <span className="icon-text">Wallet</span>
      </div>
      </Link>
      }
      {/* Only show if the user is logged in */}
      {user && <Link to="Library">
        <div className="icon-container">
          <LibraryIcon className="side-menu-icon" />
          <span className="icon-text">Your Channel</span>
        </div>
        </Link>
      }
    </div>
  );
}