import React, { useRef, useState } from "react";
import './VideoUpload.css';
import { useUser } from './UserContext';
import { toast, ToastContainer } from 'react-toastify';

const notifyUploadSuccess = () => toast.success("Resource successfully uploaded!");
const notifyUploadFailure = () => toast.error("Resource upload failed. Please ensure all fields are correct.");

const notifyUserSignInError = () => toast.error("Please create an account or sign in to upload a video.");

function VideoUpload({ user }) {
  const currentUser = useUser();
  const videoFileInputRef = useRef(); // Ref for video file input
  const thumbnailFileInputRef = useRef(); // Ref for thumbnail file input
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sharesCreated, setSharesCreated] = useState(0);
  const [sharePrice, setSharePrice] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      console.error('No user information available');
      notifyUserSignInError();
      return;
  }
    const formData = new FormData();
    formData.append("video", videoFileInputRef.current.files[0]);
    formData.append("thumbnail_image", thumbnailFileInputRef.current.files[0]);
    formData.append("user_id", user.uid);
    // formData.append("thumbnail_filepath", user.uid);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("shares_created", sharesCreated);
    formData.append("share_price", sharePrice);

    try {
      const response = await fetch(`${API_URL}/upload`, {
        method: "POST",
        body: formData,
      });

      console.log("Status:", response.status);
      console.log("Response text:", await response.text());

      if (response.ok) {
        console.log("Video uploaded successfully");
        notifyUploadSuccess();
      } else {
        console.log("Error uploading video");
        notifyUploadFailure();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="upload-container">
      <div className="upload-area" onClick={() => videoFileInputRef.current.click()}>
        <div className="upload-icon">↑</div>
        <div className="upload-text">Drag and drop video files to upload</div>
        <div className="upload-subtext">Your videos will be private until you publish them</div>
        <input
          type="file"
          ref={videoFileInputRef}
          accept="video/*"
          className="file-input"
          onChange={(e) => {/* Handle file selection */}}
        />
        <button className="select-files-button">SELECT FILES</button>
      </div>

      <form onSubmit={handleSubmit} className="form-fields">
        <div className="form-group">
          <label>Thumbnail Image</label>
          <input
            type="file"
            ref={thumbnailFileInputRef}
            accept="image/*"
            onChange={(e) => {/* Handle thumbnail selection */}}
          />
          {/* Add thumbnail preview if needed */}
        </div>

        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Add a title that describes your video"
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Tell viewers about your video"
          />
        </div>

        <div className="form-group">
          <label>Number of Shares</label>
          <input
            type="number"
            value={sharesCreated}
            onChange={(e) => setSharesCreated(e.target.value)}
            placeholder="Enter the number of shares to create"
          />
        </div>

        <div className="form-group">
          <label>Initial Share Price</label>
          <input
            type="number"
            value={sharePrice}
            onChange={(e) => setSharePrice(e.target.value)}
            placeholder="Set the initial price per share"
          />
        </div>

        <button type="submit">Upload Video</button>
      </form>
    </div>
  );
}

export default VideoUpload;
