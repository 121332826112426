import "./App.css";
import Header from "./Components/Search/Header";
import Video from "./Components/Video";
import SideMenu from "./Components/SideMenu";
import VideoUpload from "./Components/VideoUpload";
import Videolist from "./Components/Videolist";
import FilterBar from "./Components/FilterBar";
import VideoDetails from "./Components/VideoDetails";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Librarylist from "./Components/LibraryList/Librarylist";
import Walletlist from "./Components/WalletList.js";
import TradeModal from "./Components/TradeModal";
import ParentVideoSearchResult from "./Components/Search/ParentVideoSearchResult.js";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer here
import { Analytics } from "@vercel/analytics/react"
import ChannelSettings from "./pages/ChannelSettings";
// App.js
import React from "react";
import { UserProvider } from "./Components/UserContext";
import RecentViewHistory from "./Components/RecentViewHistory.js";
import RecentOrderList from './Components/RecentOrderList';
import SignUp from './pages/SignUp';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Analytics />
      <UserProvider>
        <Router>
          <header className="App-header">
            <div className="main">
              <SideMenu />
            </div>

            <Routes>
              <Route path="/" element={<ParentVideoSearchResult />} />
              <Route path="/videos/details/:id" element={<VideoDetails />} />
              <Route 
                path="/Library" 
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <Librarylist />
                  </React.Suspense>
                } 
              />
              <Route path="/Wallet" element={<Walletlist />} />
              <Route path="/view_history" element={<RecentViewHistory />} />
              <Route path="/Test" element={<TradeModal />} />
              <Route path="/order-history" element={<RecentOrderList />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/channel-settings" element={<ChannelSettings />} />
            </Routes>
          </header>
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
