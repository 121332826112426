import { useState, useEffect } from "react";
import { API_URL } from "../../../globals";

import { fetchDislikes } from "../comment.api";
export const useFetchDislikes = (commentId) => {
  const [dislikes, setDislikes] = useState(0);

  useEffect(() => {
    const getDislikes = fetchDislikes(commentId);
    setDislikes(getDislikes);
  }, [commentId]);

  return [dislikes, setDislikes];
};
