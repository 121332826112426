import { useState, useEffect } from "react";
import { API_URL } from "../../../globals";
import { fetchLikes } from "../comment.api";
export const useFetchLikes = (commentId) => {
  const [likes, setLikes] = useState(0);

  useEffect(() => {
    const getLikes = fetchLikes(commentId);
    setLikes(getLikes);
  }, [commentId]);

  return [likes, setLikes];
};
