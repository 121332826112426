import React, { useEffect, useState } from "react";
import "./Librarylist.css";
import Video from "../Video";
import { useUser } from "../UserContext";
import { updateVideoTitle, fetchVideos } from "./LibraryList.api";
import Searchbar from "../Search/Header";
import { Link } from 'react-router-dom';
import { API_URL } from "../../globals";
const VIDEOS_PER_PAGE = 9; // Set constant for videos per page

const Librarylist = () => {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useUser();
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [channelData, setChannelData] = useState({
    display_name: '',
    handle: '',
    photo_url: ''
  });

  useEffect(() => {
    const getVideos = async () => {
      setLoading(true);
      try {
        const fetchedVideos = await fetchVideos(
          currentUser?.uid, 
          currentPage, 
          VIDEOS_PER_PAGE, 
          (currentPage - 1) * VIDEOS_PER_PAGE
        );
        setVideos(fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser?.uid) {
      getVideos();
    }
  }, [currentPage, currentUser?.uid]);

  useEffect(() => {
    const fetchChannelData = async () => {
      if (currentUser?.uid) {
        try {
          const response = await fetch(`${API_URL}/user/${currentUser.uid}`);
          const data = await response.json();
          if (response.ok) {
            setChannelData({
              display_name: data.display_name || currentUser.displayName || '',
              handle: data.handle || '',
              photo_url: data.photo_url || currentUser.photoURL || ''
            });
          }
        } catch (error) {
          console.error("Error fetching channel data:", error);
        }
      }
    };

    fetchChannelData();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <div>Please log in to view your library</div>;
  }

  const handleEdit = (video) => {
    setEditingVideoId(video.id);
    setEditTitle(video.title);
  };

  const submitEdit = async (videoId) => {
    try {
      const updatedVideo = await updateVideoTitle(editTitle, videoId);
      
      if (updatedVideo) {
        setVideos(prevVideos => 
          prevVideos.map(video => 
            video.id === videoId 
              ? { ...video, title: editTitle }
              : video
          )
        );
        setEditingVideoId(null);
        setEditTitle("");
      }
    } catch (error) {
      console.error("Error updating video:", error);
      alert("Failed to update video title. Please try again.");
    }
  };

  return (
    <div>
      <Searchbar />
      <div className="library-container">
        <div className="channel-header">
          <div className="channel-info">
            <img 
              src={channelData.photo_url || '/default-avatar.png'} 
              alt="Channel" 
              className="channel-avatar"
            />
            <div className="channel-details">
              <h1>{channelData.display_name || 'My Channel'}</h1>
              <span className="channel-handle">@{channelData.handle || 'username'}</span>
            </div>
          </div>
          <Link to="/channel-settings" className="settings-button">
            Channel Settings
          </Link>
        </div>
        <div className="library-content">
          <div className="library-header">
            <h2>Your Channel</h2>
          </div>
          
          <div className="library-grid">
            {videos.map((video) => (
              <div className="library-card" key={video.id}>
                <Video 
                  className="library-video" 
                  id={video.id} 
                  title={video.title}
                />
                
                {editingVideoId === video.id ? (
                  <div className="edit-container">
                    <textarea
                      className="edit-textarea"
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      placeholder="Enter new title"
                    />
                    <button 
                      className="action-button"
                      onClick={() => submitEdit(video.id)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="card-actions">
                    <button
                      className="action-button"
                      onClick={() => handleEdit(video)}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="page-indicator">Page {currentPage}</span>
            <button 
              className="pagination-button"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={videos.length < VIDEOS_PER_PAGE}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Librarylist;
