import React, { useState, useEffect, useRef, useCallback } from 'react';

import './Videolist.css';
import Video from './Video';

const API_URL = process.env.REACT_APP_API_URL;
const VIDEOS_PER_PAGE = 12;

const Videolist = ({videos}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchedVideos, setFetchedVideos] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    
    // Create observer ref
    const observer = useRef();
    // Create ref for last video element
    const lastVideoElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const fetchVideos = async (page) => {
        if (loading || !hasMore) return; // Prevent multiple simultaneous requests
        
        setLoading(true);
        const offset = (page - 1) * VIDEOS_PER_PAGE;
        const url = `${API_URL}/listAllVideos?limit=${VIDEOS_PER_PAGE}&offset=${offset}`;
        
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Fetch error: ${response.statusText}`);
            }
            const data = await response.json();
            
            // Check if we received any new videos
            if (data.length === 0) {
                setHasMore(false);
                return;
            }

            // Check for duplicates before adding new videos
            setFetchedVideos(prev => {
                const existingIds = new Set(prev.map(video => video.id));
                const newVideos = data.filter(video => !existingIds.has(video.id));
                return [...prev, ...newVideos];
            });
            
            setHasMore(data.length === VIDEOS_PER_PAGE);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!videos || videos.length === 0) {
            fetchVideos(currentPage);
        }
    }, [currentPage, videos]);

    const displayVideos = videos && videos.length > 0 ? videos : fetchedVideos;

    return (
        <div>
            <div className='video-list-container'>
                <div className='video-list'>
                    {displayVideos.map((video, index) => {
                        // Add ref to last element
                        if (displayVideos.length === index + 1) {
                            return (
                                <div ref={lastVideoElementRef} key={video.id}>
                                    <Video id={video.id} />
                                </div>
                            );
                        } else {
                            return <Video key={video.id} id={video.id} />;
                        }
                    })}
                </div>
                {loading && (
                    <div className="loading-indicator">
                        Loading more videos...
                    </div>
                )}
            </div>
        </div>
    );
};

export default Videolist;