import { useState, useEffect } from "react";
import { API_URL } from "../../../globals";
export const useFetchCommentUserData = (userId) => {
  const [commentUserData, setCommentUserData] = useState(null);

  useEffect(() => {
    const fetchCommentUserData = async () => {
      if (!userId) return;
      
      try {
        const response = await fetch(`${API_URL}/user/${userId}`);
        const data = await response.json();
        setCommentUserData(data);
      } catch (err) {
        console.error("Error fetching comment user data", err);
      }
    };

    fetchCommentUserData();
  }, [userId]);

  return [commentUserData, setCommentUserData];
};
