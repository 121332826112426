import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51OiRL2J5aCdhChSYGGLbZyvbAWHKlncsSu7QHkjjVfNQtTSA3SWQTJOygTwI7EWqymdNiZEfXOV7fffYq3cVpNqW00ESJ8zRD7');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

