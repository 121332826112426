import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import './RecentOrderList.css';
import { useNavigate, Link } from 'react-router-dom';
import Searchbar from './Search/Header';

const API_URL = process.env.REACT_APP_API_URL;

const RecentOrderList = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentUser = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            fetchOrderHistory();
        }
    }, [currentUser]);

    const fetchOrderHistory = async () => {
        try {
            const response = await fetch(`${API_URL}/orderHistory/${currentUser.uid}`);
            if (!response.ok) {
                throw new Error('Failed to fetch order history');
            }
            const data = await response.json();
            setOrders(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching order history:', error);
            setLoading(false);
        }
    };

    const handleSearch = (event) => {
        // Implement search functionality if needed
        console.log('Search term:', event.target.value);
    };

    if (!currentUser) {
        return <div>Please log in to view order history.</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Searchbar onSearchChange={handleSearch} />
            <div className="order-history-container">
                <div className="order-history-header">
                    <h2>Order History</h2>
                    <button 
                        className="back-button"
                        onClick={() => navigate('/wallet')}
                    >
                        Back to Wallet
                    </button>
                </div>
                <table className="order-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Video</th>
                            <th>Type</th>
                            <th>Mode</th>
                            <th>Status</th>
                            <th>Shares</th>
                            <th>Price/Share</th>
                            <th>Total Value</th>
                            <th>Test Mode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(order => (
                            <tr key={order.id}>
                                <td>{new Date(order.created_at).toLocaleString()}</td>
                                <td>
                                    <Link 
                                        to={`/videos/details/${order.resource_id}`}
                                        className="video-title-link"
                                    >
                                        {order.video_title}
                                    </Link>
                                </td>
                                <td className={order.order_type === 'buy' ? 'buy-order' : 'sell-order'}>
                                    {order.order_type.toUpperCase()}
                                </td>
                                <td>{order.order_mode}</td>
                                <td className={`status-${order.order_status.toLowerCase()}`}>
                                    {order.order_status}
                                </td>
                                <td>{order.shares_order}</td>
                                <td>${Number(order.share_price).toFixed(2)}</td>
                                <td>${(order.shares_order * Number(order.share_price)).toFixed(2)}</td>
                                <td>{order.test_mode ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RecentOrderList; 