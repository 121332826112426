import React, { useEffect, useState } from "react";
import Video from "./Video";
import { useUser } from "./UserContext";
import Searchbar from "./Search/Header";
import './RecentViewHistory.css';
import { Link } from 'react-router-dom';
import { formatNumber, formatNumberWithCommas } from '../globals';

const API_URL = process.env.REACT_APP_API_URL;

const RecentViewHistory = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useUser();

  const fetchResources = async (page) => {
    try {
      setLoading(true);
      console.log('Fetching with user_id:', currentUser.uid);
      
      if (!currentUser?.uid) {
        throw new Error('No user ID available');
      }

      const response = await fetch(
        `${API_URL}/listRecentViewHistory?user_id=${currentUser.uid}&limit=10&offset=${(page - 1) * 10}`
      );
      
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`Fetch error: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Received data:', data);
      
      // Validate the data structure
      if (!Array.isArray(data)) {
        throw new Error('Received data is not an array');
      }

      setResources(data);
    } catch (error) {
      console.error("Error fetching resources:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('currentUser:', currentUser);
    if (currentUser?.uid) {
      fetchResources(currentPage);
    }
  }, [currentPage, currentUser?.uid]);

  // Add debugging for render conditions
  console.log('Component state:', { loading, error, resourcesLength: resources?.length });

  if (loading) {
    return <div className="view-history-container">Loading...</div>;
  }
  
  if (error) {
    return <div className="view-history-container">Error: {error}</div>;
  }
  
  if (!Array.isArray(resources) || resources.length === 0) {
    return <div className="view-history-container">No viewing history found.</div>;
  }

  // Debug individual resource data
  // console.log('First resource:', resources[0]);

  return (
    <div>
      <Searchbar/>
      <div className="view-history-container">
        <div className="view-history-content">
          <div className="view-history-header">
            <h2>Recent View History</h2>
          </div>
          
          <table className="view-history-table">
            <thead>
              <tr>
                <th>Video</th>
                <th>Title</th>
                <th>Creator</th>
                <th>Market Cap</th>
                <th>Price/Share</th>
                <th>Viewed On</th>
              </tr>
            </thead>
            <tbody>
              {resources.map((resource) => {
                // console.log('Rendering resource:', resource);
                const sharePrice = Number(resource.share_price) || 0;
                const sharesCreated = Number(resource.shares_created) || 0;
                const marketCap = sharePrice * sharesCreated;

                return (
                  <tr key={resource.video_id}>
                    <td className="video-preview-cell">
                      {resource.video_id && (
                        <Video
                          id={resource.video_id}
                          thumbnailOnly={true}
                        />
                      )}
                    </td>
                    <td>
                      {resource.video_id && resource.title && (
                        <Link 
                          to={`/videos/details/${resource.video_id}`}
                          className="video-title-link"
                        >
                          {resource.title}
                        </Link>
                      )}
                    </td>
                    <td>{resource.creator_name || 'Unknown Creator'}</td>
                    <td>${formatNumber(marketCap)}</td>
                    <td>${formatNumberWithCommas(sharePrice)}</td>
                    <td>{resource.viewed_at ? new Date(resource.viewed_at).toLocaleString() : 'Unknown'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>Page {currentPage}</span>
            <button 
              className="pagination-button"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={resources.length < 10}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentViewHistory;
