import React, { useRef, useState, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoUpload from "../VideoUpload";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./Searchbar.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";

import VideoCall from "@mui/icons-material/VideoCall";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { API_URL, getDaysAgo, formatNumber } from "../../globals";

import { auth } from "../../firebase";
import {
  signInWithGoogle,
  signInWithFacebook,
  signOutUser,
  signInUser,
} from "../UserAuth.api";


import DialogContentText from "@mui/material/DialogContentText";
import { useAuthState } from "react-firebase-hooks/auth";
import Avatar from "@mui/material/Avatar";
import Searchbar from "./Searchbar";

export default function Header({ onSearchChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const [user, setUser, loading, error] = useAuthState(auth);

  const [shoppingCartAnchorEl, setShoppingCartAnchorEl] = useState(null);
  const [showShoppingCartText, setShowShoppingCartText] = useState(false);

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [showNotificationText, setShowNotificationText] = useState(false);

  const [videoUploadIconAnchorEl, setVideoUploadIconAnchorEl] = useState(null);
  const [showVideoUploadIconText, setVideoUploadIconText] = useState(false);

  const [userDbData, setUserDbData] = useState(null);

  useEffect(() => {
    const signInCurrentUser = async () => {
      try {
        const currentUser = await signInUser(user);
      } catch (error) {
        console.error("Error signing in user: ", error);
      }
    };

    if (user) {
      signInCurrentUser();
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const response = await fetch(`${API_URL}/user/${user.uid}`);
          const userData = await response.json();
          setUserDbData(userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleVideoCallClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleNotificationEnter = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setShowNotificationText(true);
  };

  const handleNotificationLeave = () => {
    setNotificationAnchorEl(null);
    setShowNotificationText(false);
  };

  const handleVideoIconLeave = () => {
    setVideoUploadIconAnchorEl(null);
    setVideoUploadIconText(false);
  };

  const handleVideoIconEnter = (event) => {
    setVideoUploadIconAnchorEl(event.currentTarget);
    setVideoUploadIconText(true);
  };

  const handleSignOut = () => {
    signOutUser();
    if (profileAnchorEl) {
      setProfileAnchorEl(null);
    }
  };

  const handleUploadClick = () => {
    setAnchorEl(null);
    setIsVideoUploadOpen(true);
  };

  const handleVideoUploadClose = () => {
    setIsVideoUploadOpen(false);
  };

  const handleProfileIconClick = (event) => {
    if (profileAnchorEl) {
      setProfileAnchorEl(null);
    } else {
      setProfileAnchorEl(event.currentTarget);
    }
  };

  const handleSignInClick = () => {
    setIsSignInDialogOpen(true);
  };

  const handleSignInDialogClose = (signInCallback) => {
    setIsSignInDialogOpen(false);
    signInCallback();
  };

  const isDropdownOpen = Boolean(anchorEl);
  const isProfileDropdownOpen = Boolean(profileAnchorEl);

  return (
    <div className="main">
      <Link to="/">
        <h1>clipvest</h1>
      </Link>
      <Searchbar onSearchChange={onSearchChange} />
      <div className="icons">
        {user && (
          <div className="video-call-btn" onClick={handleVideoCallClick}>
            <div
              onMouseEnter={handleVideoIconEnter}
              onMouseLeave={handleVideoIconLeave}
              className="videoicon-container"
            >
              <VideoCall />
              {showVideoUploadIconText && (
                <span className="videoicon-text">Create</span>
              )}
            </div>
          </div>
        )}
        {user ? (
          <Avatar
            src={userDbData?.photo_url || user.photoURL}
            alt="Profile"
            className="profile-image"
            onClick={handleProfileIconClick}
          />
        ) : (
          <div className="sign-in-container" onClick={handleSignInClick}>
            <AccountCircleIcon />
            <span className="sign-in-text">Sign In</span>
          </div>
        )}
      </div>
      <Popover
        open={isDropdownOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className="dropdown">
          <div className="dropdown-item" onClick={handleUploadClick}>
            <PlayArrowIcon />
            <span>Upload Video</span>
          </div>
        </div>
      </Popover>
      {isVideoUploadOpen && (
        <Dialog open={isVideoUploadOpen} onClose={handleVideoUploadClose}>
          <DialogTitle>Upload Video</DialogTitle>
          <DialogContent>
            <VideoUpload user={user} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVideoUploadClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Popover
        open={isProfileDropdownOpen}
        anchorEl={profileAnchorEl}
        onClose={() => setProfileAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className="dropdown">
          <div className="dropdown-item">
            <AccountBoxIcon />
            <Link to='/Library'>
            <span>Your Channel</span>
            </Link>
          </div>
          <div className="dropdown-item" onClick={handleSignOut}>
            <LogoutIcon />
            <span>Sign Out</span>
          </div>
          <div className="dropdown-item">
            <ShoppingBagIcon />
            <Link to="/Wallet">
              <span>Purchases & Memberships</span>
            </Link>
          </div>
          <div className="dropdown-item">
            <FeedbackIcon />
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdybBjIe5WVRMWVkJXaS53zjQSHhAARwCwTGXJVL_hjyAxp4w/viewform'><span>Send Feedback</span></a>
          </div>
        </div>
      </Popover>
      <Dialog open={isSignInDialogOpen} onClose={handleSignInDialogClose}>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose a sign-in method:</DialogContentText>
          <Button
            onClick={() => handleSignInDialogClose(signInWithGoogle)}
            color="primary"
          >
            Sign in with Google
          </Button>
          <Button
            onClick={() => handleSignInDialogClose(signInWithFacebook)}
            color="primary"
          >
            Sign in with Facebook
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignInDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
