import React, { useState } from "react";
import Header from "./Header";
import Videolist from "../Videolist";

const ParentVideoSearchResult = () => {
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (results) => {
    setSearchResults(results);
  };

  return (
    <div>
      <Header onSearchChange={handleSearchChange} />
      <Videolist videos={searchResults} />
    </div>
  );
};

export default ParentVideoSearchResult;
